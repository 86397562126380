import { useNavigate } from 'react-router-dom'

// project imports
import useAuth from '../../hooks/useAuth'
import { GuardProps } from '../../types'
import { useEffect } from 'react'

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, isAnonymous, isLinkedUser } = useAuth()
  const navigate = useNavigate()
  const role = user?.role ?? ''
  useEffect(() => {
    if (!isAnonymous && !isLinkedUser) {
      navigate('login', { replace: true })
    } else if (role !== 'admin') {
      navigate('/prep/menu', { replace: true })
    }
  }, [isLoggedIn, navigate, role, isAnonymous, isLinkedUser])

  return children
}

export default AuthGuard
