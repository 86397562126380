import { FirebaseError } from '@firebase/util'
import {
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  getFirestore,
  query,
  collection,
  where,
  getDocs,
  orderBy,
  limit,
  Timestamp,
  QuerySnapshot,
  DocumentData,
  QueryDocumentSnapshot
} from 'firebase/firestore'

// import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import { squareCurrencyFormatted, squareDateFormatted } from '../utils/helpers'

export interface OrderData {
  createdAt: string
  lineItem: LineItem
  orderId: string
  netAmount: NetAmount
  locationId: string
  description: string
  longUrl: string
  id: string
  source: Source
  version: number
  url: string
  customer: Customer
}

export interface OrderHistoryData {
  id: string
  name: string
  description: string
  price: string
  status: string
  createdAt: string
}

export interface LineItem {
  uid: string
  quantity: string
  name: string
  basePriceMoney: BasePriceMoney
}

export interface BasePriceMoney {
  amount: string
  currency: string
}

export interface NetAmount {
  tipMoney: TipMoney
  totalMoney: TotalMoney
}

export interface TipMoney {
  amount: string
  currency: string
}

export interface TotalMoney {
  amount: string
  currency: string
}

export interface Source {
  name: string
}

export interface Customer {
  firstName: string
  lastName: string
  phone: string
  email: string
}

export interface MomDeliveredUserData {
  id?: string
  firstName: string
  lastName: string
  email?: string
  role: string
  phone: string
}

type FunctionOnDataSnapshot = (
  childSnapshot: QueryDocumentSnapshot<DocumentData, DocumentData>
) => Promise<any>

const asyncForEach = async (
  dataSnapshot: QuerySnapshot<DocumentData, DocumentData>,
  childFunction: FunctionOnDataSnapshot
) => {
  const toWait: any[] = []
  dataSnapshot.forEach((childSnapshot) => {
    toWait.push(childFunction(childSnapshot))
  })
  await Promise.all(toWait)
}

class UserRepository {
  async get(id: string) {
    const db = getFirestore()
    const docRef = doc(db, 'users', id)
    const user = await getDoc(docRef)
    if (user.exists()) {
      return user.data()
    }
    return null
  }

  async getByPhone(phone: string) {
    const db = getFirestore()

    const q = query(collection(db, 'users'), where('phone', '==', phone))

    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      return null
    } else {
      const user = querySnapshot.docs[0].data()
      return user
    }
  }

  async searchOrders(phone: string, beginDate: any | null, endDate: any | null) {
    const db = getFirestore()
    let q = query(
      collection(db, 'orders'),
      where('customer.phone', '==', phone),
      orderBy('createdAt', 'desc'),
      limit(10)
    )

    if (beginDate && endDate) {
      const beginTimestamp = Timestamp.fromDate(beginDate.toDate())
      const endTimestamp = Timestamp.fromDate(endDate.toDate())

      q = query(
        collection(db, 'orders'),
        where('customer.phone', '==', phone),
        where('orderDate', '>=', beginTimestamp),
        where('orderDate', '<=', endTimestamp),
        orderBy('orderDate', 'desc'),
        limit(10)
      )
    }

    const querySnapshot = await getDocs(q)
    const orders: OrderHistoryData[] = []

    await asyncForEach(querySnapshot, async (doc) => {
      const order = doc.data() as OrderData
      const orderId = order.orderId
      const paymentRef = await collection(db, 'orders', orderId, 'payments')

      const paymentSnapshot = await getDocs(paymentRef)
      let isCompleted = false
      await asyncForEach(paymentSnapshot, async (paymentDoc) => {
        const payment = paymentDoc.data()
        if (payment.data.object.payment.status === 'COMPLETED') {
          isCompleted = true
        }
      })

      orders.push({
        id: order.id,
        name: order.lineItem.name,
        description: order.description,
        price: squareCurrencyFormatted(order.netAmount.totalMoney.amount),
        status: isCompleted ? 'COMPLETED' : 'APPROVED',
        createdAt: squareDateFormatted(order.createdAt)
      })
    })

    return orders
  }

  async getOrderHistory(phone: string) {
    const db = getFirestore()

    const q = query(
      collection(db, 'orders'),
      where('customer.phone', '==', phone),
      orderBy('createdAt', 'desc'),
      limit(10)
    )

    const querySnapshot = await getDocs(q)

    const orders: OrderHistoryData[] = []
    await asyncForEach(querySnapshot, async (doc) => {
      const order = doc.data() as OrderData
      const orderId = order.orderId
      const paymentRef = await collection(db, 'orders', orderId, 'payments')

      const paymentSnapshot = await getDocs(paymentRef)
      let isCompleted = false
      await asyncForEach(paymentSnapshot, async (paymentDoc) => {
        const payment = paymentDoc.data()
        if (payment.data.object.payment.status === 'COMPLETED') {
          isCompleted = true
        }
      })

      if (isCompleted) {
        orders.push({
          id: order.id,
          name: order.lineItem.name,
          description: order.description,
          price: squareCurrencyFormatted(order.netAmount.totalMoney.amount),
          status: isCompleted ? 'COMPLETED' : 'APPROVED',
          createdAt: squareDateFormatted(order.createdAt)
        })
      }
    })

    return orders
  }

  async update(phoneNumber: string, data: MomDeliveredUserData) {
    const db = getFirestore()

    const q = query(collection(db, 'users'), where('phone', '==', phoneNumber))

    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      throw new FirebaseError('not-found', 'User with that phone number does not exist')
    } else {
      const userDoc = querySnapshot.docs[0]
      const userRef = await doc(db, 'users', `${userDoc.id}`)
      await setDoc(
        userRef,
        { firstName: data.firstName, lastName: data.lastName, email: data.email },
        { merge: true }
      )
      return (await getDoc(userRef)).data()
    }
  }

  async add(userId: string, data: MomDeliveredUserData) {
    const db = getFirestore()

    const userRef = await doc(db, 'users', `${userId}`)
    const existingUser = await getDoc(userRef)

    if (existingUser.exists()) {
      throw new FirebaseError(
        'already-exists',
        'User with that mobile number already exists already exists'
      )
    } else {
      await setDoc(userRef, data, { merge: true })
      return data
    }
  }

  async delete(id: string) {
    const db = getFirestore()
    const docRef = doc(db, 'users', id)
    const existingUser = await getDoc(docRef)

    if (existingUser.exists()) {
      await deleteDoc(docRef)
    }
  }
}

export default UserRepository
