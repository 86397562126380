import { useState, useRef, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Badge, Box, ButtonBase } from '@mui/material'

import { useNavigate } from 'react-router-dom'

import { useRecoilValue } from 'recoil'

// project imports
import { cartStatus } from '../../../../recoil/menu'

// assets
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone'

export interface NotificationData {
  id?: string
  subject: string
  message: string
  read: boolean
  created: any
}

// ==============================|| NOTIFICATION ||============================== //

const ShoppingCartSection = () => {
  const theme = useTheme()
  // const { user } = useAuth()
  const [open] = useState(false)
  const navigate = useNavigate()
  const status = useRecoilValue(cartStatus)
  const { hasItems } = status
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null)

  const handleNavigate = () => {
    return navigate('/prep/cart')
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return hasItems ? (
    <Box
      sx={{
        ml: 2,
        mr: 3,
        [theme.breakpoints.down('md')]: {
          mr: 2
        }
      }}
    >
      <Badge
        badgeContent={status.totalItems}
        invisible={false}
        color="secondary"
        variant="standard"
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.warning.dark
                  : theme.palette.secondary.dark,
              '&:hover': {
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.warning.dark
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.grey[800]
                    : theme.palette.secondary.light
              }
            }}
            ref={anchorRef}
            onClick={handleNavigate}
            color="inherit"
          >
            <ShoppingCartTwoToneIcon />
          </Avatar>
        </ButtonBase>
      </Badge>
    </Box>
  ) : (
    <></>
  )
}

export default ShoppingCartSection
