import React from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Divider, List, Typography } from '@mui/material'

// project imports
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'
import { GenericCardProps } from '../../../../../types'
import useAuth from '../../../../../hooks/useAuth'

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export interface NavGroupProps {
  item: {
    id?: string
    type?: string
    icon?: GenericCardProps['iconPrimary']
    children?: NavGroupProps['item'][]
    title?: React.ReactNode | string
    caption?: React.ReactNode | string
    color?: 'primary' | 'secondary' | 'default' | undefined
  }
}

const NavGroup = ({ item }: NavGroupProps) => {
  const theme = useTheme()
  const { user } = useAuth()

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} />
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  if ((item.id === 'organization' && user?.role === 'admin') || item.id !== 'organization') {
    return (
      <>
        <List
          subheader={
            item.title && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.menuCaption }}
                display="block"
                gutterBottom
              >
                {item.title}
                {item.caption && (
                  <Typography
                    variant="caption"
                    sx={{ ...theme.typography.subMenuCaption }}
                    display="block"
                    gutterBottom
                  >
                    {item.caption}
                  </Typography>
                )}
              </Typography>
            )
          }
        >
          {items}
        </List>

        {/* group divider */}
        <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      </>
    )
  } else {
    return null
  }
}

export default NavGroup
