// import React from 'react'
// import { useNavigate } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project imports
import useAuth from '../../../../hooks/useAuth'
import useScriptRef from '../../../../hooks/useScriptRef'
import AnimateButton from '../../../../ui-component/extended/AnimateButton'
import { Stack } from '@mui/system'

const UserDetails = () => {
  const theme = useTheme()
  const scriptedRef = useScriptRef()
  const { user, createUser } = useAuth()

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Need a Few More Details</Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First Name is required'),
          lastName: Yup.string().required('Last Name is required'),
          email: Yup.string().email().optional()
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await createUser(user?.id as string, {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              role: 'member',
              phone: user?.phone as string
            })
          } catch (err: any) {
            console.error(err)
            if (scriptedRef.current) {
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Stack direction="column" spacing={2}>
              <FormControl
                error={Boolean(touched.firstName && errors.firstName)}
                sx={{ ...theme.typography.customInput }}
              >
                <TextField
                  id="outlined-adornment-verification"
                  type="text"
                  value={values.firstName}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="First Name"
                  inputProps={{}}
                />
                {touched.firstName && errors.firstName && (
                  <FormHelperText error id="standard-weight-helper-text-verification">
                    {errors.firstName}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={Boolean(touched.lastName && errors.lastName)}
                sx={{ ...theme.typography.customInput }}
              >
                <TextField
                  id="outlined-adornment-verification"
                  type="text"
                  value={values.lastName}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Last Name"
                  inputProps={{}}
                />
                {touched.lastName && errors.lastName && (
                  <FormHelperText error id="standard-weight-helper-text-verification">
                    {errors.lastName}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={Boolean(touched.email && errors.email)}
                sx={{ ...theme.typography.customInput }}
              >
                <TextField
                  id="outlined-adornment-verification"
                  type="text"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Email"
                  inputProps={{}}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text-verification">
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Save Details
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}

export default UserDetails
