import { atom } from 'recoil'

export type MenuItem = {
  id: string
  title: string
  price: number
}

export type CartItem = {
  id: string
  title: string
  option: string
  price: number
  qty: number
}

export type CartItemToDelete = {
  id: string
  option: string
}

export type LinkItem = {
  id: string
  version: number
  description: string
  orderId: string
  url: string
  longUrl: string
  createdAt: string
  locationId: string
}

// export interface CartQty {
//   qty: number
// }

// export type CartItem = MenuItem & CartQty

export const menu: Array<MenuItem> = [
  {
    id: '1',
    title: 'Tv',
    price: 240
  },
  {
    id: '2',
    title: 'Ps4',
    price: 500
  },
  {
    id: '3',
    title: 'The last of us II',
    price: 250
  },
  {
    id: '4',
    title: 'Monitor',
    price: 990
  },
  {
    id: '5',
    title: 'Mac pro',
    price: 1400
  },
  {
    id: '6',
    title: 'Fone',
    price: 150
  }
]

export const menuState = atom({
  key: `menuState`,
  default: menu
})

export const cartState = atom({
  key: 'cartState',
  default: new Array<CartItem>()
})

export const paymentLinkState = atom({
  key: 'paymentLinkState',
  default: undefined
})
