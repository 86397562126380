import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const squareCurrencyFormatted = (currency: string) => {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(Number(currency) / 100)
  return formattedCurrency
}

export const squareDateFormatted = (utcTimestamp: string) => {
  const formattedDate = dayjs.utc(utcTimestamp).local().format('M-DD-YYYY h:mm A')
  return formattedDate
}
