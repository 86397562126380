// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconChefHat,
  IconToolsKitchen,
  IconUsers,
  IconUser,
  IconReportSearch,
  IconShoppingCart
} from '@tabler/icons'

// constant
const icons = {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconChefHat,
  IconToolsKitchen,
  IconUsers,
  IconUser,
  IconReportSearch,
  IconShoppingCart
}

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const organization = {
  id: 'organization',
  title: <FormattedMessage id="organization" />,
  type: 'group',
  children: [
    {
      id: 'recipes',
      title: (
        <>
          <FormattedMessage id="recipes" />
        </>
      ),
      type: 'item',
      url: '/admin/recipe',
      icon: icons.IconChefHat
    },
    {
      id: 'menu-items',
      title: (
        <>
          <FormattedMessage id="menu-items" />
        </>
      ),
      type: 'item',
      url: '/admin/menu',
      icon: icons.IconChefHat
    },
    {
      id: 'orders',
      title: (
        <>
          <FormattedMessage id="orders" />
        </>
      ),
      type: 'item',
      url: '/admin/orders',
      icon: icons.IconReportSearch
    },
    {
      id: 'grocery',
      title: (
        <>
          <FormattedMessage id="grocery" />
        </>
      ),
      type: 'item',
      url: '/admin/grocery',
      icon: icons.IconShoppingCart
    }
  ]
}

export default organization
