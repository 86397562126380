import { lazy } from 'react'

// project imports
import MainLayout from '../layout/MainLayout'
import Loadable from '../ui-component/Loadable'

// Mom Delivered routes
// const JoinForm = Loadable(lazy(() => import('../views/organization/join')))
// const UserNotFoundView = Loadable(
//   lazy(() => import('../views/pages/authentication/momdeliveredauth/UserNotAdded'))
// )

// // org management
// const OrgAccountEdit = Loadable(
//   lazy(() => import('../views/organization/subscription/Subscription'))
// )
// const OrgLocationEdit = Loadable(lazy(() => import('../views/organization/locations')))
// // const OrgTeamEdit = Loadable(lazy(() => import('../views/organization/teams')))
// const OrgUserEdit = Loadable(lazy(() => import('../views/organization/users')))

// const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')))

// // widget routing
// const WidgetStatistics = Loadable(lazy(() => import('../views/widget/Statistics')))
// const WidgetData = Loadable(lazy(() => import('../views/widget/Data')))
// const WidgetChart = Loadable(lazy(() => import('../views/widget/Chart')))

// internal contact page
const InternalContact = Loadable(lazy(() => import('../views/organization/contact-us')))

// faqs
const FAQs = Loadable(lazy(() => import('../views/pages/maintenance/FAQ')))

// landing
const PagesLanding = Loadable(lazy(() => import('../views/pages/landing')))

// profile

const Profile = Loadable(lazy(() => import('../views/profile')))

// foodprep
const FoodPrepMenu = Loadable(lazy(() => import('../views/prep/menu')))
const MenuDetail = Loadable(lazy(() => import('../views/prep/detail')))
const FoodPrepCart = Loadable(lazy(() => import('../views/prep/cart')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <PagesLanding />
    },
    {
      path: '/contact',
      element: <InternalContact />
    },
    {
      path: '/profile',
      element: <Profile />
    },
    {
      path: '/prep/menu',
      element: <FoodPrepMenu />
    },
    {
      path: '/prep/menu/detail/:id',
      element: <MenuDetail />
    },
    {
      path: '/prep/cart',
      element: <FoodPrepCart />
    },
    {
      path: '/faq',
      element: <FAQs />
    }
    // {
    //   path: '/widget/statistics',
    //   element: <WidgetStatistics />
    // },
    // {
    //   path: '/widget/data',
    //   element: <WidgetData />
    // },
    // {
    //   path: '/widget/chart',
    //   element: <WidgetChart />
    // },

    // {
    //   path: '/dashboard/analytics',
    //   element: <DashboardAnalytics />
    // }
  ]
}

export default MainRoutes
