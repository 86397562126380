// action - state management
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  UPDATE_USER,
  RECAPTCHA_CAPTURED,
  CREATE_USER,
  ANONYMOUS_LOGIN
} from './actions'
import { initialLoginContextProps } from '../types'

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isAnonymous: false,
  isInitialized: false,
  user: null,
  recaptchaVerified: false,
  recaptchaTimedOut: false,
  isLinkedUser: false
}

export interface AccountReducerActionProps {
  type: string
  payload?: initialLoginContextProps
}

const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!
      return {
        ...state,
        user
      }
    }
    case UPDATE_USER: {
      const { user } = action.payload!
      return {
        ...state,
        user
      }
    }
    case CREATE_USER: {
      const { user } = action.payload!
      return {
        ...state,
        isLinkedUser: true,
        user
      }
    }
    case LOGIN: {
      const { user, isLinkedUser } = action.payload!
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        isLinkedUser,
        user
      }
    }
    case ANONYMOUS_LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        isLinkedUser: false,
        isAnonymous: true
      }
    }
    case RECAPTCHA_CAPTURED: {
      const { recaptchaVerified } = action.payload!
      return {
        ...state,
        recaptchaVerified
      }
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        recaptchaVerified: false,
        isLinkedUser: false,
        user: null
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default accountReducer
