import { selectorFamily, selector } from 'recoil'

import { cartState, paymentLinkState } from './atoms'

export const cartStatus = selector({
  key: 'cartStatus',
  get: ({ get }) => {
    const cart = get(cartState)
    const totalItems = cart.length
    const totalPrice = cart.reduce((total, { qty, price }) => total + price, 0)
    const totalMeals = cart.reduce((total, { qty }) => total + qty, 0)
    const hasItems = totalItems > 0
    return {
      totalItems,
      totalPrice,
      hasItems,
      totalMeals
    }
  }
})

export const cartQty = selectorFamily({
  key: 'item',
  get: (itemId) => ({ get }) => {
    const cart = get(cartState)

    const match = cart.find((i) => i.id === itemId)
    const qty = match ? match.qty : 0
    return qty
  }
})

export const paymentLink = selector({
  key: 'paymentLink',
  get: ({ get }) => {
    const paymentLink = get(paymentLinkState)
    return paymentLink
  }
})
