// third-party
import { FormattedMessage } from 'react-intl'

// assets
import { IconDeviceAnalytics, IconSoup } from '@tabler/icons'
import { OverrideIcon } from '../types'

// constant
const icons = {
  IconSoup,
  IconDeviceAnalytics
}

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export interface FoodPrepMenuProps {
  id: string
  title: React.ReactNode | string
  type: string
  children: {
    id: string
    title: React.ReactNode | string
    type: string
    url: string
    icon: OverrideIcon
    breadcrumbs: boolean
  }[]
}

const foodprepMenus: FoodPrepMenuProps = {
  id: 'prep',
  title: <FormattedMessage id="prep" />,
  type: 'group',
  children: [
    {
      id: 'menu',
      title: <FormattedMessage id="menu" />,
      type: 'item',
      url: '/prep/menu',
      icon: icons.IconSoup,
      breadcrumbs: true
    }

    // {
    //   id: 'analytics',
    //   title: <FormattedMessage id="analytics" />,
    //   type: 'item',
    //   url: '/dashboard/analytics',
    //   icon: icons.IconDeviceAnalytics,
    //   breadcrumbs: false
    // }
  ]
}

export default foodprepMenus
