// import React from 'react'
// import { useNavigate } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project imports
import useAuth from '../../../../hooks/useAuth'
import useScriptRef from '../../../../hooks/useScriptRef'
import AnimateButton from '../../../../ui-component/extended/AnimateButton'
import NumberFormat from 'react-number-format'

const PhoneNumber = () => {
  const theme = useTheme()
  const scriptedRef = useScriptRef()

  const { firebaseRegisterAndSigninWithPhone } = useAuth()

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Enter Your Cell Phone Number</Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          phone: '',
          verifyCode: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string()
            // .min(10, 'Phone number should be in 9999999999 format')
            // .max(10, 'Phone number should be in 9999999999 format')
            .required('Mobile phone number is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await firebaseRegisterAndSigninWithPhone(`+1${values.phone}`).then(
              () => {
                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                // github issue: https://github.com/formium/formik/issues/2430
                // navigate('/check')
              },
              (err: any) => {
                if (scriptedRef.current) {
                  setStatus({ success: false })
                  setErrors({ submit: err.message })
                  setSubmitting(false)
                }
              }
            )
          } catch (err: any) {
            console.error(err)
            if (scriptedRef.current) {
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(touched.phone && errors.phone)}
              sx={{ ...theme.typography.customInput }}
            >
              <NumberFormat
                name="outlined-adornment-phone-login"
                id="phone"
                type="tel"
                onBlur={handleBlur}
                label="Mobile Number"
                autoFocus
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
                value={values.phone}
                customInput={TextField}
                format="+1 (###) ###-####"
                mask="_"
                required
                onValueChange={(e) => {
                  setFieldValue('phone', e.value)
                }}
              />
              {touched.phone && errors.phone && (
                <FormHelperText error id="standard-weight-helper-text-phone-login">
                  {errors.phone}
                </FormHelperText>
              )}
            </FormControl>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  id="btn-login-phone"
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Sign in
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}

export default PhoneNumber
