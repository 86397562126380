import ReactDOM from 'react-dom'

// third party
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { RecoilRoot } from 'recoil'

// project imports
import * as serviceWorker from './serviceWorker'
import App from './App'
import { store } from './store'

// style + assets
import './assets/scss/style.scss'
import config from './config'

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <RecoilRoot>
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </Provider>
  </RecoilRoot>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
