// import React from 'react'
// import { useNavigate } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project imports
import useAuth from '../../../../hooks/useAuth'
import useScriptRef from '../../../../hooks/useScriptRef'
import AnimateButton from '../../../../ui-component/extended/AnimateButton'

const Verify = () => {
  const theme = useTheme()
  const scriptedRef = useScriptRef()

  const { verifyPhoneAuthentication } = useAuth()

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Enter Text Verification Code</Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          verifyCode: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          verifyCode: Yup.string().required('Verification code is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            verifyPhoneAuthentication(values.verifyCode).then(
              () => {
                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                // github issue: https://github.com/formium/formik/issues/2430
                // navigate('/check')
              },
              (err: any) => {
                if (scriptedRef.current) {
                  setStatus({ success: false })
                  setErrors({ submit: err.message })
                  setSubmitting(false)
                }
              }
            )
          } catch (err: any) {
            console.error(err)
            if (scriptedRef.current) {
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(touched.verifyCode && errors.verifyCode)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-verification">
                Text Verification Code
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-verification"
                type="text"
                value={values.verifyCode}
                autoFocus
                name="verifyCode"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Text Verification Code"
                inputProps={{}}
              />
              {touched.verifyCode && errors.verifyCode && (
                <FormHelperText error id="standard-weight-helper-text-verification">
                  {errors.verifyCode}
                </FormHelperText>
              )}
            </FormControl>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Verify
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}

export default Verify
