import { lazy } from 'react'
import { useRoutes } from 'react-router-dom'

// routes
import MainRoutes from './MainRoutes'
import LoginRoutes from './LoginRoutes'
import AdminRoutes from './AdminRoutes'
import Loadable from '../ui-component/Loadable'

// const PagesLanding = Loadable(lazy(() => import('views/pages/landing')))
const Error = Loadable(lazy(() => import('../views/pages/maintenance/Error')))
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([{ path: '*', element: <Error /> }, MainRoutes, LoginRoutes, AdminRoutes])
}
