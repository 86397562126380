import React, { createContext, useEffect, useReducer } from 'react'

// third-party
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
  User
} from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
// action - state management
import {
  LOGOUT,
  UPDATE_USER,
  RECAPTCHA_CAPTURED,
  LOGIN,
  CREATE_USER,
  ANONYMOUS_LOGIN
} from '../store/actions'
import accountReducer from '../store/accountReducer'

// project imports
import config from '../config'
import { initialLoginContextProps } from '../types'
import { FirebaseContextType, PhoneVerificationResult } from '../types/auth'
import Loader from '../ui-component/Loader'
import UserRepository, { MomDeliveredUserData } from '../repository/User'

export type MomDeliveredUserProfile = {
  id?: string
  avatar?: string
  image?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  role?: string
}

// firebase initialize
const firebaseApp = initializeApp(config.firebase)
getAnalytics(firebaseApp)
const auth = getAuth(firebaseApp)

const functions = getFunctions(firebaseApp)
const firestore = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  // connectAuthEmulator(auth, 'http://localhost:9099/')
  console.log('connecting to local emulators')
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFirestoreEmulator(firestore, 'localhost', 8082)
  connectStorageEmulator(storage, 'localhost', 9199)
}

// const
const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isAnonymous: false,
  isInitialized: false,
  recaptchaVerified: false,
  recaptchaTimedOut: false,
  user: null,
  isLinkedUser: false
}

const userRepository = new UserRepository()

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null)

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState)

  useEffect(
    () =>
      onAuthStateChanged(auth, (user) => {
        if (user && user.providerData[0] && user.providerData[0].uid) {
          // get user details from firestore
          const userId = user.uid
          user.getIdToken().then(async (token) => {
            const momUser = await userRepository.get(userId)
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                isAnonymous: user.isAnonymous,
                isInitialized: true,
                recaptchaTimedOut: false,
                recaptchaVerified: true,
                isLinkedUser: momUser !== null,
                user: {
                  id: user.uid,
                  email: momUser?.email!,
                  firstName: momUser?.firstName,
                  lastName: momUser?.lastName,
                  phone: user.phoneNumber,
                  role: momUser?.role
                } as MomDeliveredUserProfile
              }
            })
          })
        } else {
          dispatch({
            type: LOGOUT
          })
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  const updateUser = async (existingUser: MomDeliveredUserProfile, updatedUser: any) => {
    await userRepository.update(existingUser.phone as string, updatedUser).then((result) => {
      dispatch({
        type: UPDATE_USER,
        payload: {
          isLoggedIn: true,
          isAnonymous: false,
          isInitialized: true,
          isLinkedUser: true,
          recaptchaVerified: true,
          recaptchaTimedOut: false,
          user: { ...existingUser, ...updatedUser }
        }
      })
      return {
        successful: true
      }
    })
  }

  const createUser = async (id: string, user: MomDeliveredUserData) => {
    await userRepository.add(id, user).then((result) => {
      dispatch({
        type: CREATE_USER,
        payload: {
          ...state,
          isLinkedUser: true,
          user: {
            id: id,
            email: user.email,
            firstName: user?.firstName,
            lastName: user?.lastName
          } as MomDeliveredUserProfile
        }
      })
      return {
        successful: true
      }
    })
  }
  const anonymousSignIn = async () => {
    const alreadyLoggedIn = auth.currentUser && !auth.currentUser.isAnonymous
    if (!alreadyLoggedIn) {
      signInAnonymously(auth).then((userCredential) => {
        dispatch({
          type: ANONYMOUS_LOGIN
        })
      })
    }
  }

  const firebaseRegisterAndSigninWithPhone = async (phoneNumber: string) => {
    const appVerifier = new RecaptchaVerifier(auth, 'btn-login-phone', {
      size: 'invisible',
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      }
    })

    window.recaptchaVerifier = appVerifier

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        // window.localStorage.setItem('confirmationResult', confirmationResult)
        // window.location.href = '/check'
        window.confirmationResult = confirmationResult
        // setPhoneNumber(phoneNumber)
        dispatch({
          type: RECAPTCHA_CAPTURED,
          payload: {
            ...state,
            recaptchaVerified: true
          }
        })
      })
      .catch((error) => {
        console.log(error)
        // window.location.reload()
      })
  }

  const verifyPhoneAuthentication = async (url: string): Promise<PhoneVerificationResult> => {
    window.confirmationResult
      .confirm(url)
      .then((result: any) => {
        // User signed in successfully.
        const user = result.user as User
        return {
          successful: true,
          user: user
        }
        // ...
      })
      .catch((error: any) => {
        console.log(error)
        window.location.reload()
        return {
          successful: false
        }
      })

    return {
      successful: false
    }
  }

  // Confirm the link is a sign-in with email link.

  const logout = () => signOut(auth)

  const updateProfile = () => {}
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegisterAndSigninWithPhone,
        verifyPhoneAuthentication,
        login: () => {},
        logout,
        updateProfile,
        updateUser,
        createUser,
        anonymousSignIn
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseContext
