import { lazy } from 'react'

// project imports
import MainLayout from '../layout/MainLayout'
import Loadable from '../ui-component/Loadable'
import AuthGuard from '../utils/route-guard/AuthGuard'

// Mom Delivered routes
const RecipeManagement = Loadable(lazy(() => import('../views/admin/recipe')))
const MenuManagement = Loadable(lazy(() => import('../views/admin/menu')))
const OrderManagement = Loadable(lazy(() => import('../views/admin/orders')))
const ShoppingList = Loadable(lazy(() => import('../views/admin/grocery')))
// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: 'admin',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'recipe',
      element: <RecipeManagement />
    },
    {
      path: 'menu',
      element: <MenuManagement />
    },
    {
      path: 'orders',
      element: <OrderManagement />
    },
    {
      path: 'grocery',
      element: <ShoppingList />
    }
  ]
}

export default AdminRoutes
