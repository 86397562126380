// import { lazy } from 'react'

// project imports
// import GuestGuard from '../utils/route-guard/GuestGuard'
import MinimalLayout from '../layout/MinimalLayout'
import NavMotion from '../layout/NavMotion'
// import Loadable from '../ui-component/Loadable'
import AuthLogin from '../views/pages/authentication/momdeliveredauth/Login'
// login routing
// const AuthLogin = Loadable(
//   lazy(() => import('../views/pages/authentication/momdeliveredauth/Login'))
// )
// const AuthRegister = Loadable(
//   lazy(() => import('../views/pages/authentication/momdeliveredauth/Register'))
// )

// const AuthCheckMail = Loadable(
//   lazy(() => import('../views/pages/authentication/momdeliveredauth/CheckMail'))
// )

// const AuthVerify = Loadable(
//   lazy(() => import('../views/pages/authentication/momdeliveredauth/Verify'))
// )

// const AuthVerified = Loadable(
//   lazy(() => import('../views/pages/authentication/momdeliveredauth/Verified'))
// )

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      {/* <GuestGuard>
        <MinimalLayout />
      </GuestGuard> */}
      <MinimalLayout />
    </NavMotion>
  ),
  children: [
    {
      path: '/login',
      element: <AuthLogin isNavigation={true} navigate="/admin/recipe" />
    }
    // {
    //   path: '/register',
    //   element: <AuthRegister />
    // },
    // {
    //   path: '/check',
    //   element: <AuthCheckMail />
    // },
    // {
    //   path: '/verify',
    //   element: <AuthVerify />
    // },
    // {
    //   path: '/verified',
    //   element: <AuthVerified />
    // }
  ]
}

export default LoginRoutes
