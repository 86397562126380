// material-ui

import logo from '../assets/images/mom_delivered.webp'

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src={logo} alt="mom-delivered" height="59" width="175" />
}

export default Logo
