// material-ui
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

// project imports
import useAuth from '../../../../hooks/useAuth'

const Welcome = () => {
  const { user } = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Grid container direction="column" justifyContent="center" spacing={2}>
      <Grid item xs={12} container alignItems="center" justifyContent="center">
        <Box sx={{ mb: 2 }}>
          {!isMobile && (
            <Typography variant="subtitle1">
              Hello {user?.firstName}! Personal information up to date, you can now continue to payment
            </Typography>
          )}
          {isMobile && (
            <Typography align='center' variant="subtitle1">
              Hello {user?.firstName}! <br />
              You can now continue to payment
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Welcome
