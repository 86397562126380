import { PaletteMode } from '@mui/material'

const config: {
  basename: string
  defaultPath: string
  fontFamily: string
  borderRadius: number
  outlinedFilled: boolean
  theme: PaletteMode
  presetColor: string
  i18n: string
  rtlLayout: boolean
  imageBaseUrl: string
  squareOrderUrl: string
  firebase: {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
  }
} = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/mom-delivered/react/default'
  basename: '',
  defaultPath: '/',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  theme: 'light', // light, dark
  presetColor: 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
  i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  imageBaseUrl:
    process.env.REACT_APP_IMAGE_BASE_URL ||
    `http://127.0.0.1:9199/v0/b/mom-delivered.appspot.com/o/`,
  squareOrderUrl:
    process.env.REACT_APP_SQUARE_ORDER_URL ||
    'https://squareupsandbox.com/dashboard/sales/transactions/',
  rtlLayout: false,
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ''
  }
}

export default config
