import { useRecoilState } from 'recoil'

import { CartItem, CartItemToDelete, cartState } from './atoms'

export const useAddMenuItem = () => {
  const [cart, setCart] = useRecoilState(cartState)

  return (menuItem: CartItem) => {
    const existingCartItem = cart.find(
      (item) => item.id === menuItem.id && item.option === menuItem.option
    )
    if (existingCartItem === undefined) {
      return setCart([...cart, { ...menuItem, qty: menuItem.qty }])
    } else {

      const existingCart = cart.filter(
        (item) => !(item.id === menuItem.id && item.option === menuItem.option)
      )

      return setCart([
        ...existingCart,
        {
          ...existingCartItem,
          qty: existingCartItem.qty + menuItem.qty,
          price: existingCartItem.price + menuItem.price
        }
      ])
    }
  }
}

export const useRemoveMenuItem = () => {
  const [cart, setCart] = useRecoilState(cartState)
  return (product: CartItemToDelete) => {
    const existingCartItem = cart.find(
      (item) => item.id === product.id && item.option === product.option
    )
    if (existingCartItem === undefined) {
      return
    }

    const existingCart = cart.filter(
      (item) => !(item.id === product.id && item.option === product.option)
    )

    return setCart([...existingCart])
  }
}
