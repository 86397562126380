// project imports
import useAuth from '../../../../hooks/useAuth'
import Verify from './Verify'
import PhoneNumber from './PhoneNumber'
import Welcome from './Welcome'
import UserDetails from './UserDetails'
import { Navigate } from 'react-router-dom'
// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = (props: { isNavigation: boolean; navigation?: string }, { ...others }) => {
  const { recaptchaVerified, isAnonymous, isLoggedIn, isLinkedUser } = useAuth()
  const { isNavigation, navigation } = props
  // const navigate = useNavigate()

  return !isAnonymous && isLoggedIn ? (
    isLinkedUser ? (
      isNavigation ? (
        <Navigate to={navigation as string} />
      ) : (
        <Welcome />
      )
    ) : (
      <UserDetails />
    )
  ) : recaptchaVerified ? (
    <Verify />
  ) : (
    <PhoneNumber />
  )
}

export default FirebaseLogin
