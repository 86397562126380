// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
  IconKey,
  IconReceipt2,
  IconBug,
  IconBellRinging,
  IconPhoneCall,
  IconCheck,
  IconBulldozer,
  IconHelp
} from '@tabler/icons'

// constant
const icons = {
  IconKey,
  IconCheck,
  IconReceipt2,
  IconBug,
  IconBellRinging,
  IconPhoneCall,
  IconBulldozer,
  IconHelp
}

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const support = {
  id: 'support',
  title: <FormattedMessage id="support" />,
  caption: <FormattedMessage id="support-caption" />,
  type: 'group',
  children: [
    {
      id: 'faq',
      title: <FormattedMessage id="faq" />,
      type: 'item',
      url: '/faq',
      icon: icons.IconHelp,
      target: false
    },
    {
      id: 'contact-us',
      title: <FormattedMessage id="contact-us" />,
      type: 'item',
      icon: icons.IconPhoneCall,
      url: '/contact',
      target: false
    }
  ]
}

export default support
