import prepMenus from './prep'
import organization from './organization'
import support from './support'
import { NavItemType } from '../types'

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [prepMenus, organization, support]
}

export default menuItems
